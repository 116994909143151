import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./HomeScreen.scss"
import JobsComponent from "./JobsComponent"
import { Helmet } from "react-helmet"
import Logo from "./Logo"
import SocialMediaIcons from "./SocialMediaIcons"
import CTA from "./SocialIconCTA"

export default function HomeScreenComponent(props) {
  return (
    <Container>
      <Helmet>
        <title>
          IQ Inc. Careers | Join our IQ Inc. team and make an impact!
        </title>
        <meta
          name="description"
          content="At IQ, we are interested in your personal and professional growth. Check out our openings and
          contact us if you see a fit. We would love to meet you!"
        />
      </Helmet>
      <Row className="top-row-margin row-margins">
        <Col>
          <a href="https://iq-inc.com/" title="IQ Inc">
            <Logo />
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="job-openings-heading">JOB OPENINGS</div>
        </Col>
      </Row>
      <Row className="row-margins">
        <Col md={{ span: 8, offset: 2 }}>
          <div className="blurb-summary">
            At IQ, we are interested in our employees’ personal, as well as
            professional, growth. Through team building, social gatherings, and
            coming together to support a good cause, we build a collaborative
            and caring environment, all while providing exceptional technical
            services to our clients. Check out our openings listed below and
            contact us if you see a fit. We would love to get to know you!
          </div>
        </Col>
      </Row>
      <Row>
        <SocialMediaIcons />
      </Row>
      <CTA />
      <Col className="gray-line-separator">
        <Row>
          <div className="job-details-gray-line"></div>
        </Row>
      </Col>
      <Row>
        <Col>
          <JobsComponent jobs={props.jobs} />
        </Col>
      </Row>
    </Container>
  )
}
