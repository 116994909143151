import React from "react"
import HomeScreenComponent from "../components/HomeScreenComponent"
import { graphql } from "gatsby"

export default function IndexPage({ data }) {
  return <HomeScreenComponent jobs={data.iqjobads.jobAds} />
}

export const query = graphql`
  {
    iqjobads {
      jobAds {
        title
        slug
        summary
      }
    }
  }
`
